import { Expose, Type } from "class-transformer"
import NetworkBlockType from "./NetworkBlockType"
import NetworkBanner from "../banners/NetworkBanner"
import NetworkBlockProductCategory from "../block-product-categories/NetworkBlockProductCategory"
import Header from "../../../domain/entities/header/Header"
import NetworkHeader from "../header/NetworkHeader"
import NetworkProductsSet from "../products-sets/NetworkProductsSet"
import NetworkMenuItem from "../menu-items/NetworkMenuItem"
import NetworkButton from "../buttons/NetworkButton"

export default class NetworkBlock {
  @Expose()
  readonly id: number | null | undefined

  @Expose()
  readonly position: number | null | undefined

  @Expose()
  readonly clientId: string | null | undefined

  @Expose()
  readonly name: string | null | undefined

  @Expose()
  readonly type: NetworkBlockType | null | undefined

  @Expose()
  readonly text: string | null | undefined

  @Expose()
  @Type(() => NetworkBanner)
  readonly banners: NetworkBanner[] | null | undefined

  @Expose()
  readonly columnsCount: number | null | undefined

  @Expose()
  @Type(() => NetworkBlockProductCategory)
  readonly blockProductCategories: NetworkBlockProductCategory[] | null | undefined

  @Expose()
  @Type(() => NetworkProductsSet)
  readonly productsSet: NetworkProductsSet | null | undefined

  @Expose()
  @Type(() => NetworkHeader)
  readonly header: Header | null | undefined

  @Expose()
  @Type(() => NetworkMenuItem)
  readonly menuItems: NetworkMenuItem[] | null | undefined

  @Expose()
  @Type(() => NetworkButton)
  readonly button: NetworkButton | null | undefined

  constructor(parameters?: {
    readonly id: number | null | undefined
    readonly position: number | null | undefined
    readonly clientId: string | null | undefined
    readonly name: string | null | undefined
    readonly type: NetworkBlockType | null | undefined
    readonly text: string | null | undefined
    readonly banners: NetworkBanner[] | null | undefined
    readonly columnsCount: number | null | undefined
    readonly blockProductCategories: NetworkBlockProductCategory[] | null | undefined
    readonly productsSet: NetworkProductsSet | null | undefined
    readonly header: Header | null | undefined
    readonly menuItems: NetworkMenuItem[] | null | undefined
    readonly button: NetworkButton | null | undefined
  }) {
    if (parameters === undefined) {
      return
    }

    this.id = parameters.id
    this.position = parameters.position
    this.clientId = parameters.clientId
    this.name = parameters.name
    this.type = parameters.type
    this.banners = parameters.banners
    this.columnsCount = parameters.columnsCount
    this.blockProductCategories = parameters.blockProductCategories
    this.productsSet = parameters.productsSet
    this.header = parameters.header
    this.menuItems = parameters.menuItems
    this.button = parameters.button
    this.text = parameters.text
  }
}
