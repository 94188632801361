import { Expose, Type } from "class-transformer"
import BlockErrorsObject from "../../../domain/entities/blocks/BlockErrorsObject"
import NetworkBlockErrorsObjectAttributes from "./NetworkBlockErrorsObjectAttributes"
import NetworkBannerErrorsObject from "../banners/NetworkBannerErrorsObject"
import NetworkBlockProductCategoryErrorsObject
  from "../block-product-categories/NetworkBlockProductCategoryErrorsObject"
import NetworkHeaderErrorsObject from "../header/NetworkHeaderErrorsObject"
import NetworkProductsSetErrorsObject from "../products-sets/NetworkProductsSetErrorsObject"
import NetworkMenuItemErrorsObject from "../menu-items/NetworkMenuItemErrorsObject"
import NetworkButtonErrorsObject from "../buttons/NetworkButtonErrorsObject"

export default class NetworkBlockErrorsObject implements BlockErrorsObject {
  @Expose()
  readonly clientId: string | null | undefined

  @Expose()
  @Type(() => NetworkBlockErrorsObjectAttributes)
  readonly attributes: NetworkBlockErrorsObjectAttributes | null | undefined

  @Expose()
  @Type(() => NetworkBannerErrorsObject)
  readonly banners: NetworkBannerErrorsObject[] | null | undefined

  @Expose()
  @Type(() => NetworkBlockProductCategoryErrorsObject)
  readonly blockProductCategories: NetworkBlockProductCategoryErrorsObject[] | null | undefined

  @Expose()
  @Type(() => NetworkProductsSetErrorsObject)
  readonly productsSet: NetworkProductsSetErrorsObject | null | undefined

  @Expose()
  @Type(() => NetworkHeaderErrorsObject)
  readonly header: NetworkHeaderErrorsObject | null | undefined

  @Expose()
  @Type(() => NetworkMenuItemErrorsObject)
  readonly menuItems: NetworkMenuItemErrorsObject[] | null | undefined

  @Expose()
  @Type(() => NetworkButtonErrorsObject)
  readonly button: NetworkButtonErrorsObject | null | undefined
}
