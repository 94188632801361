import Button from "../../domain/entities/buttons/Button"
import NetworkButton from "../entities/buttons/NetworkButton"
import { v4 as uuidv4 } from "uuid"

export default class ButtonsMapper {
  mapNetworkToDomain({
    button
  }: {
    readonly button: NetworkButton
  }): Button {
    return {
      id: button.id,
      caption: button.caption,
      blockId: button.blockId,
      clientId: uuidv4()
    }
  }

  mapDomainToNetwork({
    button
  }: {
    readonly button: Button
  }): NetworkButton {
    return new NetworkButton({
      id: button.id,
      caption: button.caption,
      blockId: button.blockId,
      clientId: button.clientId
    })
  }
}
