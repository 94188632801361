import ViewModel from "../../../../../../sqadmin/lib/view-model/ViewModel"
import SubscribeToObjectsEventsUseCase
  from "../../../../../../sqadmin/features/objects/domain/use-cases/objects/SubscribeToObjectsEventsUseCase"
import UnsubscribeFromObjectsEventsUseCase
  from "../../../../../../sqadmin/features/objects/domain/use-cases/objects/UnsubscribeFromObjectsEventsUseCase"
import ObjectsPresentationLogicParameters
  from "../../../../../../sqadmin/features/objects/presentation/entities/objects/ObjectsPresentationLogicParameters"
import ObjectsPresentationLogic
  from "../../../../../../sqadmin/features/objects/presentation/presentation-logics/ObjectsPresentationLogic"
import { StateObservable } from "../../../../../../sqadmin/lib/view-model/StateObservable"
import {
  ObjectsPageViewState
} from "../../../../../../sqadmin/features/objects/presentation/view-states/ObjectsViewState"
import { ActionObservable } from "../../../../../../sqadmin/lib/view-model/ActionObservable"
import {
  ObjectsViewAction
} from "../../../../../../sqadmin/features/objects/presentation/view-actions/ObjectsViewAction"
import autoBind from "auto-bind"
import ObjectsViewEvent from "../../../../../../sqadmin/features/objects/presentation/view-events/ObjectsViewEvent"
import TableColumn from "../../../../../../sqadmin/features/objects/presentation/entities/tables/TableColumn"
import TextTableValue
  from "../../../../../../sqadmin/features/objects/presentation/entities/tables/table-value-by-type/TextTableValue"
import GetOrdersUseCase from "../../../domain/use-cases/orders/GetOrdersUseCase"
import Order from "../../../../../core/domain/entities/orders/Order"
import CoreI18n from "../../../../../core/i18n/CoreI18n"
import DateTimeTableValue
  from "../../../../../../sqadmin/features/objects/presentation/entities/tables/table-value-by-type/DateTimeTableValue"
import User from "../../../../../core/domain/entities/users/User"
import PhoneNumber from "../../../../../core/domain/entities/phone-numbers/PhoneNumber"
import CoreUrlProvider from "../../../../../core/presentation/services/CoreUrlProvider"
import isPresent from "../../../../../../sqadmin/lib/isPresent"
import CoreTextProvider from "../../../../../core/i18n/CoreTextProvider"
import assertNever from "../../../../../../sqadmin/lib/assertNever"
import OrderSourceType from "../../../../../core/domain/entities/orders/OrderSourceType"
import isBlank from "../../../../../../sqadmin/lib/isBlank"

export default class OrdersViewModel extends ViewModel {
  private readonly coreI18n: CoreI18n
  private readonly subscribeToObjectsEventsUseCase: SubscribeToObjectsEventsUseCase
  private readonly unsubscribeFromObjectsEventsUseCase: UnsubscribeFromObjectsEventsUseCase
  private readonly getOrdersUseCase: GetOrdersUseCase
  private readonly objectsPresentationLogicParameters: ObjectsPresentationLogicParameters
  private readonly objectsPresentationLogic: ObjectsPresentationLogic<Order>

  readonly observableObjectsPageViewState: StateObservable<ObjectsPageViewState<Order>>
  readonly observableObjectsViewAction: ActionObservable<ObjectsViewAction>

  constructor(parameters: {
    readonly coreI18n: CoreI18n
    readonly subscribeToObjectsEventsUseCase: SubscribeToObjectsEventsUseCase
    readonly unsubscribeFromObjectsEventsUseCase: UnsubscribeFromObjectsEventsUseCase
    readonly getOrdersUseCase: GetOrdersUseCase
    readonly objectsPresentationLogicParameters: ObjectsPresentationLogicParameters
  }) {
    super()
    autoBind(this)
    this.coreI18n = parameters.coreI18n
    this.subscribeToObjectsEventsUseCase = parameters.subscribeToObjectsEventsUseCase
    this.unsubscribeFromObjectsEventsUseCase = parameters.unsubscribeFromObjectsEventsUseCase
    this.getOrdersUseCase = parameters.getOrdersUseCase
    this.objectsPresentationLogicParameters = parameters.objectsPresentationLogicParameters
    this.objectsPresentationLogic = this.createObjectsPresentationLogic()
    this.observableObjectsPageViewState = this.objectsPresentationLogic.observableObjectsPageViewState
    this.observableObjectsViewAction = this.objectsPresentationLogic.observableObjectsViewAction
  }

  protected onDestroy() {
    super.onDestroy()
    this.objectsPresentationLogic.destroy()
  }

  onObjectsViewEvent(objectsViewEvent: ObjectsViewEvent) {
    this.objectsPresentationLogic.onObjectsViewEvent(objectsViewEvent)
  }

  private createObjectsPresentationLogic(): ObjectsPresentationLogic<Order> {
    const coreUrlProvider = new CoreUrlProvider()
    const coreTextProvider: CoreTextProvider = this.coreI18n.getTextProvider()

    return new ObjectsPresentationLogic<Order>({
      ...this.objectsPresentationLogicParameters,
      subscribeToObjectsEventsUseCase: this.subscribeToObjectsEventsUseCase,
      unsubscribeFromObjectsEventsUseCase: this.unsubscribeFromObjectsEventsUseCase,
      getObjects: async({
        query,
        sort,
        lastObject: lastOrder
      }) => {
        return await this.getOrdersUseCase.call({
          filter: { query },
          sort,
          pagination: { id: lastOrder?.id ?? undefined }
        })
      },
      getObjectId: (order: Order) => order.id!.toString(),
      columns: [
        new TableColumn<Order>({
          title: coreTextProvider.number(),
          createValue: (order: Order) => new TextTableValue({
            value: order.number
          })
        }),
        new TableColumn<Order>({
          title: coreTextProvider.orderedAt(),
          createValue: (order: Order) => new DateTimeTableValue({
            value: order.orderedAt
          })
        }),
        new TableColumn<Order>({
          title: coreTextProvider.client(),
          createValue: (order: Order) => new TextTableValue({
            value: this.formatUserInfo(order.user),
            url: coreUrlProvider.buildClientUrl({
              id: order.user!.id!
            })
          })
        }),
        new TableColumn<Order>({
          title: coreTextProvider.processingStatus(),
          createValue: (order: Order) => new TextTableValue({
            value: order.processingStatus?.name
          })
        }),
        new TableColumn<Order>({
          title: coreTextProvider.paymentStatus(),
          createValue: (order: Order) => new TextTableValue({
            value: order.orderPayment?.payment?.status?.displayName
          })
        }),
        new TableColumn<Order>({
          title: coreTextProvider.receivingMethod(),
          createValue: (order: Order) => new TextTableValue({
            value: order.orderReceiving?.method?.name
          })
        }),
        new TableColumn<Order>({
          title: coreTextProvider.paymentMethod(),
          createValue: (order: Order) => new TextTableValue({
            value: order.orderPayment?.method?.name
          })
        }),
        new TableColumn<Order>({
          title: coreTextProvider.externalCode(),
          createValue: (order: Order) => new TextTableValue({
            value: order.externalCode
          })
        })
      ]
    })
  }

  private formatUserInfo(user: User | null | undefined) {
    const phoneNumber = this.formatPhoneNumber(user?.phoneNumber)
    const fullName = [
      user?.profile?.firstName,
      user?.profile?.lastName
    ].filter(value => isPresent(value))

    if (fullName.length === 0) return phoneNumber

    return [fullName.join(" "), phoneNumber].join("\n")
  }

  private formatPhoneNumber(phoneNumber: PhoneNumber | null | undefined): string | null | undefined {
    return [
      phoneNumber?.country?.callingCode,
      phoneNumber?.value
    ]
      .filter((value: string | null | undefined) => isPresent(value))
      .join("")
  }

  private detectOrderSourceTypeDisplayName(type: OrderSourceType | null | undefined): string | null | undefined {
    if (isBlank(type)) return null

    const coreTextProvider: CoreTextProvider = this.coreI18n.getTextProvider()
    switch (type) {
      case OrderSourceType.ONLINE_ORDER:
        return coreTextProvider.orderSourceTypeOnline()
      case OrderSourceType.OFFLINE_SALE:
        return coreTextProvider.orderSourceTypeOffline()
      default:
        assertNever(type)
    }
  }
}
