import NetworkApiLogItemsRequestFilter from "../entities/NetworkApiLogItemsRequestFilter"
import ApiLogItemsFilter from "../../../../core/domain/entities/api-log-items/ApiLogItemsFilter"
import ExtendedDate from "../../../../../sqadmin/lib/extended-date/ExtendedDate"
import isPresent from "../../../../../sqadmin/lib/isPresent"

export default class ApiLogItemsFiltersMapper {
  mapDomainToNetwork({
    filter,
    query
  }: {
    readonly filter: ApiLogItemsFilter | null | undefined
    readonly query: string | null | undefined
  }): NetworkApiLogItemsRequestFilter {
    const startedAt = filter?.startedAt
    const extendedDate = isPresent(startedAt) ? new ExtendedDate(startedAt) : undefined
    return new NetworkApiLogItemsRequestFilter({
      query: query,
      serviceType: filter?.serviceType,
      httpStatus: filter?.httpStatus,
      direction: filter?.direction,
      actionType: filter?.actionType,
      resultType: filter?.resultType,
      startedAtTo: extendedDate?.getEndOfDay(),
      startedAtFrom: extendedDate?.getStartOfDay()
    })
  }
}
