import { Expose, Type } from "class-transformer"
import ButtonErrorsObjectAttributes from "../../../domain/entities/buttons/ButtonErrorsObjectAttributes"
import NetworkAttributeError from "../errors/NetworkAttributeError"

export default class NetworkButtonErrorsObjectAttributes implements ButtonErrorsObjectAttributes {
  @Expose()
  @Type(() => NetworkAttributeError)
  readonly id: NetworkAttributeError[] | null | undefined

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly caption: NetworkAttributeError[] | null | undefined

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly blockId: NetworkAttributeError[] | null | undefined

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly clientId: NetworkAttributeError[] | null | undefined
}
