import { Expose } from "class-transformer"

export default class NetworkButton {
  @Expose()
  readonly id: number | null | undefined

  @Expose()
  readonly caption: string | null | undefined

  @Expose()
  readonly blockId: number | null | undefined

  @Expose()
  readonly clientId: string | null | undefined

  constructor(parameters?: {
    readonly id: number | null | undefined
    readonly caption: string | null | undefined
    readonly blockId: number | null | undefined
    readonly clientId: string | null | undefined
  }) {
    if (parameters === undefined) {
      return
    }

    this.id = parameters.id
    this.caption = parameters.caption
    this.blockId = parameters.blockId
    this.clientId = parameters.clientId
  }
}
