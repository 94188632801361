enum ApiLogItemServiceType {
  SYNCHRONIZATION = "synchronization",
  CDEK = "cdek",
  YOOKASSA = "yookassa",
  DA_DATA = "daData",
  SMS_AERO = "smsAero",
  DOLYAME = "dolyame",
  FIREBASE_CLOUD_MESSAGING = "firebaseCloudMessaging",
  HUAWEI_PUSH_KIT = "huaweiPushKit"
}

export default ApiLogItemServiceType
