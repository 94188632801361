import { Expose, Type } from "class-transformer"
import AttributeError from "../../../../../sqadmin/core/domain/entities/AttributeError"
import Button from "../../../domain/entities/buttons/Button"
import ButtonErrorsObject from "../../../domain/entities/buttons/ButtonErrorsObject"
import { ErrorsObjectAttributes } from "../../../domain/entities/errors/ErrorsObjectAttributes"
import NetworkButtonErrorsObjectAttributes from "./NetworkButtonErrorsObjectAttributes"

export default class NetworkButtonErrorsObject implements ButtonErrorsObject {
  @Expose()
  @Type(() => NetworkButtonErrorsObjectAttributes)
  readonly attributes: ErrorsObjectAttributes<Button, AttributeError[]> | null | undefined

  @Expose()
  readonly clientId: string | null | undefined
}
